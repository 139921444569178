import './App.css';
import { Col, ConfigProvider, Row } from 'antd';

import { DevelopmentMapContainer } from './components/DevelopmentMapContainer';
import { DevelopmentMapToolbar } from './components/DevelopmentMapToolbar';
import { defaultThemeColor } from './components/utils';
import { MapFilterProvider } from './contexts/MapFilterContext';

import 'leaflet/dist/leaflet.css';

function App() {
    return (
        <ConfigProvider theme={{ token: { colorPrimary: defaultThemeColor } }}>
            <MapFilterProvider>
                <Row style={{ height: '100vh', flexDirection: 'column' }}>
                    <Col>
                        <DevelopmentMapToolbar />
                    </Col>
                    <Col flex="auto">
                        <DevelopmentMapContainer />
                    </Col>
                </Row>
            </MapFilterProvider>
        </ConfigProvider>
    );
}

export default App;
