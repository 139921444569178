import * as React from 'react';

export interface MapFilters {
    minNumberofHousingUnits: number;
    earliestYearIssued: number;
}

export interface MapFilterContextType {
    mapFilters: MapFilters;
    setMapFilters: React.Dispatch<React.SetStateAction<MapFilters>>;
}

const MapFilterContext = React.createContext({
    mapFilters: {},
    setMapFilters: () => {
        throw new Error(
            'setMapFilters function must be overridden by the provider'
        );
    }
} as unknown as MapFilterContextType);

export const useMapFilters = () => React.useContext(MapFilterContext);

const defaultMapFilters: MapFilters = {
    minNumberofHousingUnits: 10,
    earliestYearIssued: 2020
};

export const MapFilterProvider: React.FC<{ children: React.ReactNode }> = ({
    children
}) => {
    const [mapFilters, setMapFilters] =
        React.useState<MapFilters>(defaultMapFilters);

    return (
        <MapFilterContext.Provider value={{ mapFilters, setMapFilters }}>
            {children}
        </MapFilterContext.Provider>
    );
};
