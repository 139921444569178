import { Button, Modal } from 'antd';
import { CSSProperties, Dispatch, SetStateAction } from 'react';

interface DevelopmentMapModalProps {
    modalOpen: boolean;
    setModalOpen: Dispatch<SetStateAction<boolean>>;
    modalTitle: string;
    children: React.ReactNode;
    submitButtonText?: string;
    handleSubmit?: () => void;
}

const DevelopmentMapModal: React.FC<DevelopmentMapModalProps> = ({
    modalOpen,
    setModalOpen,
    modalTitle,
    children,
    submitButtonText,
    handleSubmit
}) => {
    const modalTitleStyle: CSSProperties = {
        fontSize: '20px',
        marginBottom: 24
    };

    const footer =
        submitButtonText && handleSubmit
            ? [
                  <Button key="back" onClick={() => setModalOpen(false)}>
                      Cancel
                  </Button>,
                  <Button
                      key="submit"
                      type="primary"
                      onClick={() => {
                          handleSubmit();
                          setModalOpen(false);
                      }}
                  >
                      {submitButtonText}
                  </Button>
              ]
            : [
                  <Button
                      key="submit"
                      type="primary"
                      onClick={() => {
                          setModalOpen(false);
                      }}
                  >
                      {'Close'}
                  </Button>
              ];

    return (
        <Modal
            title={<div style={modalTitleStyle}>{modalTitle}</div>}
            open={modalOpen}
            onCancel={() => setModalOpen(false)}
            centered={true}
            footer={footer}
        >
            {children}
        </Modal>
    );
};

export default DevelopmentMapModal;
