import { useMemo, useState } from 'react';

import { MapFilters, useMapFilters } from '../contexts/MapFilterContext';

export enum PermitStatus {
    FINAL = 'Final',
    ACTIVE = 'Active',
    EXPIRED = 'Expired',
    PENDING = 'Pending Permit',
    ABORTED = 'Aborted'
}

export enum DataLoadingStatus {
    LOADING = 'loading',
    SUCCESS = 'success',
    ERROR = 'error'
}

interface AusConstructionPermitRawData {
    project_id: string;
    latitude: string;
    longitude: string;
    housing_units: string;
    original_address1: string;
    calendar_year_issued: string;
    status_current: string;
}

export interface AusConstructionPermit {
    projectId: number;
    latitude: number;
    longitude: number;
    numberOfHousingUnits: number;
    address: string;
    calendarYearIssued: number;
    currentStatus: PermitStatus;
}

const fetchConstructionPermits = async (mapFilters: MapFilters) => {
    const mapFiltersQueryString = Object.entries(mapFilters)
        .map(
            ([key, value]) =>
                `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        )
        .join('&');

    const response = await fetch(
        `/.netlify/functions/fetchAustinConstructionPermits?${mapFiltersQueryString}`
    );
    if (!response.ok) {
        throw new Error(
            `Failed to fetch Austin construction permit data with status ${response.status}: ${response.statusText}`
        );
    }
    return response.json();
};

export const useGetConstructionPermits = (): {
    status: DataLoadingStatus;
    data: AusConstructionPermit[];
} => {
    const [status, setStatus] = useState<DataLoadingStatus>(
        DataLoadingStatus.LOADING
    );
    const [data, setData] = useState<AusConstructionPermit[]>([]);
    const { mapFilters } = useMapFilters();

    useMemo(() => {
        const fetchData = async () => {
            setStatus(DataLoadingStatus.LOADING);

            fetchConstructionPermits(mapFilters)
                .then((data) => {
                    const ausConstructionPermits: AusConstructionPermit[] =
                        data.map(
                            (rawPermitData: AusConstructionPermitRawData) => {
                                return {
                                    projectId: parseInt(
                                        rawPermitData.project_id
                                    ),
                                    latitude: parseFloat(
                                        rawPermitData.latitude
                                    ),
                                    longitude: parseFloat(
                                        rawPermitData.longitude
                                    ),
                                    numberOfHousingUnits: parseInt(
                                        rawPermitData.housing_units
                                    ),
                                    address: rawPermitData.original_address1,
                                    calendarYearIssued: parseInt(
                                        rawPermitData.calendar_year_issued
                                    ),
                                    currentStatus:
                                        rawPermitData.status_current as PermitStatus
                                };
                            }
                        );
                    setData(ausConstructionPermits);
                    setStatus(DataLoadingStatus.SUCCESS);
                })
                .catch((error) => {
                    console.error(
                        'Error occurred while fetching construction permit data',
                        error
                    );
                    setData([]);
                    setStatus(DataLoadingStatus.ERROR);
                });
        };

        fetchData();
    }, [mapFilters]);

    return { status, data };
};
