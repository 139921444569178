import { Space } from 'antd';
import { Dispatch, SetStateAction, useState } from 'react';

import { MapFilters, useMapFilters } from '../contexts/MapFilterContext';

import DevelopmentMapModal from './DevelopmentMapModal';
import { MinNumberofHoustingUnitsFilter } from './filters/MinNumberOfUnitsFilter';
import { YearPicker } from './filters/YearFilter';

interface FilterModalProps {
    modalOpen: boolean;
    setModalOpen: Dispatch<SetStateAction<boolean>>;
}

const FilterModal: React.FC<FilterModalProps> = ({ ...props }) => {
    const { mapFilters, setMapFilters } = useMapFilters();

    // Local state to handle input
    const [localMapFilters, setLocalMapFilters] = useState<MapFilters>({
        minNumberofHousingUnits: mapFilters?.minNumberofHousingUnits ?? 0,
        earliestYearIssued: mapFilters?.earliestYearIssued ?? 2020
    });

    return (
        <DevelopmentMapModal
            modalTitle={'Filter Projects'}
            submitButtonText={'Apply Filters'}
            handleSubmit={() => setMapFilters(localMapFilters)}
            {...props}
        >
            <Space
                direction="vertical"
                size="middle"
                style={{ display: 'flex' }}
            >
                <MinNumberofHoustingUnitsFilter
                    mapFilters={localMapFilters}
                    setMapFilters={setLocalMapFilters}
                />
                <YearPicker
                    mapFilters={localMapFilters}
                    setMapFilters={setLocalMapFilters}
                />
            </Space>
        </DevelopmentMapModal>
    );
};

export default FilterModal;
