import { Typography } from 'antd';
import { Dispatch, SetStateAction } from 'react';

import { AusConstructionPermit } from '../hooks/useGetConstructionPermits';

import DevelopmentMapModal from './DevelopmentMapModal';

const { Text } = Typography;

interface ProjectModalProps {
    modalOpen: boolean;
    setModalOpen: Dispatch<SetStateAction<boolean>>;
    modalContent: AusConstructionPermit | undefined;
}

const ProjectModal: React.FC<ProjectModalProps> = ({
    modalContent,
    ...props
}) => {
    return (
        <>
            <DevelopmentMapModal modalTitle={'Project Details'} {...props}>
                <div id="project-modal-description">
                    <Text>
                        <b>Project address: </b>
                        {modalContent?.address}
                    </Text>
                    <br />
                    <Text>
                        <b>Number of housing units: </b>
                        {modalContent?.numberOfHousingUnits}
                    </Text>
                    <br />
                    <Text>
                        <b>Current project status: </b>
                        {modalContent?.currentStatus}
                    </Text>
                </div>
            </DevelopmentMapModal>
        </>
    );
};

export default ProjectModal;
