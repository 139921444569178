import { FilterOutlined } from '@ant-design/icons';
import { Button, Layout, Typography } from 'antd';
import React, { useState } from 'react';

import FilterModal from './FilterModal';
import { defaultThemeColor } from './utils';

const { Header } = Layout;
const { Title } = Typography;

export const DevelopmentMapToolbar: React.FC = () => {
    const [modalOpen, setModalOpen] = useState<boolean>(false);

    return (
        <>
            <Header
                style={{
                    backgroundColor: defaultThemeColor,
                    display: 'flex',
                    padding: '0 20px',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    color: 'white'
                }}
            >
                <Title
                    level={3}
                    style={{ color: 'inherit', margin: 0, flexGrow: 1 }}
                >
                    BUILDING AUSTIN
                </Title>
                <Button
                    type="text"
                    icon={<FilterOutlined style={{ fontSize: '24px' }} />}
                    onClick={() => setModalOpen(true)}
                    style={{
                        color: 'inherit',
                        padding: '12px',
                        display: 'inline-flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: 'auto',
                        height: 'auto'
                    }}
                />
            </Header>
            <FilterModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
        </>
    );
};
