import { DatePicker, DatePickerProps, Form } from 'antd';
import dayjs, { Dayjs } from 'dayjs';

import { MapFilterContextType } from '../../contexts/MapFilterContext';

export const YearPicker: React.FC<MapFilterContextType> = ({
    mapFilters: localMapFilters,
    setMapFilters: setLocalMapFilters
}) => {
    const handleYearChange: DatePickerProps['onChange'] = (
        date,
        dateString
    ) => {
        const dateToSet =
            typeof dateString === 'string' ? dateString : dateString[0];
        setLocalMapFilters({
            ...localMapFilters,
            earliestYearIssued: dateString ? parseInt(dateToSet, 10) : 2020
        });
    };

    const disabledDate = (current: Dayjs) => {
        const minYear = 1980;
        const maxYear = dayjs().year(); // Current year
        // Disable dates outside the min and max year range
        return (
            current && (current.year() < minYear || current.year() > maxYear)
        );
    };

    return (
        <Form.Item label="Earliest year issued" style={{ marginBottom: 0 }}>
            <DatePicker
                onChange={handleYearChange}
                disabledDate={disabledDate}
                picker="year"
                defaultValue={dayjs(
                    `${localMapFilters.earliestYearIssued}-01-01`
                )}
            />
        </Form.Item>
    );
};
