import { Form, InputNumber } from 'antd';

import { MapFilterContextType } from '../../contexts/MapFilterContext';

export const MinNumberofHoustingUnitsFilter: React.FC<MapFilterContextType> = ({
    mapFilters: localMapFilters,
    setMapFilters: setLocalMapFilters
}) => {
    const handleChange = (value: number | null): void => {
        setLocalMapFilters({
            ...localMapFilters,
            ...{
                minNumberofHousingUnits: value ?? 1
            }
        });
    };

    return (
        <Form.Item
            label="Minimum number of housing units"
            style={{ marginBottom: 0 }}
        >
            <InputNumber
                value={localMapFilters.minNumberofHousingUnits}
                placeholder="Minimum number of housing units"
                min={1}
                max={3000}
                onChange={handleChange}
                style={{ width: '100%' }}
            />
        </Form.Item>
    );
};
