import L from 'leaflet';

export const defaultThemeColor = '#0B6E4F';

const defaultIconHtml = `<div style="
    background-color: ${defaultThemeColor};
    border: 2px solid black !important;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
">
    <div style="
        background-color: white !important;
        border-radius: 50%;
        width: 10px;
        height: 10px;
    "></div>
</div>`;

const DEFAULT_ICON = L.divIcon({
    className: 'default-project-icon',
    html: defaultIconHtml,
    iconSize: [20, 20],
    iconAnchor: [10, 10],
    popupAnchor: [0, -10]
});

export const DevMapIcons = {
    DEFAULT_ICON
};
