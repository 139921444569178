import { Alert } from 'antd';
import { LatLngExpression } from 'leaflet';
import React, { useState } from 'react';
import { MapContainer, Marker, TileLayer } from 'react-leaflet';

import {
    AusConstructionPermit,
    DataLoadingStatus,
    useGetConstructionPermits
} from '../hooks/useGetConstructionPermits';

import { MapLoadingOverlay } from './DevelopmentMapLoadingOverlay';
import ProjectModal from './ProjectModal';
import { DevMapIcons } from './utils';

interface MarkerProps {
    permitData: AusConstructionPermit[];
    onMarkerClick: (permit: AusConstructionPermit) => void;
}

const MapMarkers: React.FC<MarkerProps> = ({ permitData, onMarkerClick }) => {
    return (
        <>
            {permitData
                .filter(
                    (permit: AusConstructionPermit) =>
                        permit.latitude && permit.longitude
                )
                .map((permit: AusConstructionPermit, index: number) => (
                    <Marker
                        key={index}
                        icon={DevMapIcons.DEFAULT_ICON}
                        position={[permit.latitude, permit.longitude]}
                        eventHandlers={{
                            click: () => onMarkerClick(permit)
                        }}
                    />
                ))}
        </>
    );
};

export const DevelopmentMapContainer: React.FC = () => {
    const austinCenter: LatLngExpression = [30.266, -97.733];
    const zoom = 13;

    const { status, data } = useGetConstructionPermits();

    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [modalContent, setModalContent] = useState<AusConstructionPermit>();

    const handleMarkerClick = (permit: AusConstructionPermit) => {
        setModalContent(permit);
        setModalOpen(true);
    };

    return (
        <div style={{ position: 'relative', height: '100%' }}>
            {status === DataLoadingStatus.ERROR && (
                <Alert
                    style={{
                        position: 'absolute',
                        zIndex: 1000,
                        top: 10,
                        left: '50%',
                        transform: 'translateX(-50%)', // Center the alert horizontally
                        width: '90%',
                        maxWidth: '600px'
                    }}
                    type="error"
                    message="The request to the City of Austin's data portal failed."
                    description="Check https://data.austintexas.gov to confirm the site is available."
                    showIcon
                />
            )}
            <MapContainer
                center={austinCenter}
                zoom={zoom}
                scrollWheelZoom={true}
                style={{ height: '100%', width: '100%', position: 'relative' }}
            >
                <TileLayer
                    url={`https://tile.thunderforest.com/mobile-atlas/{z}/{x}/{y}.png?apikey=${process.env.REACT_APP_THUNDERFOREST_API_KEY}`}
                    attribution='&copy; <a href="http://www.thunderforest.com/">Thunderforest</a>, &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
                <MapMarkers
                    permitData={data}
                    onMarkerClick={handleMarkerClick}
                />
                <MapLoadingOverlay
                    isLoading={status === DataLoadingStatus.LOADING}
                />
            </MapContainer>
            <ProjectModal
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
                modalContent={modalContent}
            />
        </div>
    );
};
